:root {
	font-family: var(--font-family);
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}

body {
	background-color: var(--bg-c1);
	color: var(--text-c1);
	margin: 0;
	font-size: 14px;
	line-height: 1.42857143;
}

::after, ::before {
	box-sizing: border-box;
}

hr {
	margin-top: 1em;
	margin-bottom: 1em;
	border: 0;
	border-top: 0.1em solid var(--fg-c1);
}

textarea {
	height: auto !important;
}

/* prevent default margin bottom 5px */
label {
	margin-bottom: 0;
	font-weight: 700;
}

a {
	background-color: transparent;
}

* {
	box-sizing: border-box;
}

.ps-hide-webkit-scrollbar::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar {
	width: 0.4em;
}

::-webkit-scrollbar:hover {
	width: 0.9em;
}

::-webkit-scrollbar-thumb {
	background-color: var(--text-c4);
	border-radius: 0.5em;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--text-c2);
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 0.5em;
}

.h3, h3 {
	font-size: 24px;
}

.h1, .h2, .h3, h1, h2, h3 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: 350;
}